<div id="app-container">
  <header id="header-section">
    <app-header></app-header>
  </header>
  <main id="main-section">
      <div id="sidebar-section">
        <router-outlet name="sidebar"></router-outlet>
      </div>
      <div id="content-section">
        <router-outlet></router-outlet>
      </div>
  </main>
  <footer id="footer-section">
    <app-footer></app-footer>
  </footer>
</div>
