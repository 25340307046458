<h2>Verkoop</h2>
Wij hebben alpaca's te koop in diverse prijsklassen. <a routerLink="/alpacas/forsale" routerLinkActive="active">Zie hier</a>

<a routerLink="/alpacas/forsale" routerLinkActive="active">
  <img src="/assets/images/pages/fei.jpg" alt="Verkoop">
</a>

<h2>AAB Lid</h2>
Alpacamundo is lid van de Alpaca Association Benelux.
<a href="http://www.alpaca-benelux.com" target="_blank">
  <img src="/assets/images/pages/aab_logo.png" alt="Alpaca Association Benelux (AAB)">
</a>
<h2>Folder</h2>
<p>
  <a href="/assets/pdf/Alpacamundo_folder_v4.0.pdf" target="_blank">Download hier onze folder</a>
<p>  
  <a href="/assets/pdf/Alpacamundo_folder_v4.0.pdf" target="_blank">
    <img src="/assets/images/pages/folder.png" alt="Alpacamundo folder">
  </a>
