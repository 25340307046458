<div id="footer">
  <span class="footer-text">© Copyright 2024 Alpacamundo - v{{getProjectVersion()}}</span>
  <span class="login">  
    <ng-container *ngIf="!isUserLoggedIn; else loggedIn">
      <a role="button" (click)="login()" (keydown)="login()" title="Login" rel="login" tabindex="0" style="cursor: pointer;">login here</a>
      </ng-container>
    <ng-template #loggedIn>
      <a role="button" (click)="logout()" (keydown)="login()" title="Logout" rel="logout" tabindex="0" style="cursor: pointer;">logout</a>
    </ng-template> 
  </span>
</div>